var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "시설등급별 배출기준량",
                  tableId: "processManual",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.grid.height,
                  data: _vm.grid.data,
                  columnSetting: false,
                  isFullScreen: false,
                  usePaging: false,
                  expandAll: true,
                  selection: "multiple",
                  rowKey: "col0",
                },
                on: { linkClick: _vm.linkClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "LBLADD", icon: "add" },
                        }),
                        _c("c-btn", {
                          attrs: { label: "LBLREMOVE", icon: "remove" },
                        }),
                        _c("c-btn", {
                          attrs: { label: "LBLSAVE", icon: "save" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }